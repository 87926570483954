import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

//jQuery initialization
import $ from "jquery";

//functions
import {
    _t,
    getCookie,
    getSystemSettings,
} from "../functions/Functions";
import { useTranslation } from "react-i18next";

import { SettingsContext } from "../contexts/Settings";
//axios and base url
import axios from "axios";
// import { BASE_URL } from "../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const Preloaderone = () => {
    const { t } = useTranslation();
    const history = useHistory();

    //getting context values here
    let {
        loading,
        setLoading,
        generalSettings,
    } = useContext(SettingsContext);





    //state hooks here
    const [otp, setOtp] = useState('');

    useEffect(() => {
        handleJquery();
    }, []);

    //jQuery
    const handleJquery = () => {
        //obj Image Animation
        var hoverLayer = $("body");
        var objImgOne = $(".fk-global-img__obj");

        //Animation Init
        hoverLayer.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 60;
            var valueY = (e.pageY * -1) / 80;
            if (objImgOne.length) {
                objImgOne.css({
                    transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
                });
            }
        });
    };

    // const refreshPage = () => {
    //     window.location.reload();
    // }

    const style = {
        logo: {
            backgroundColor: "none",
            // generalSettings &&
            // getSystemSettings(generalSettings, "type_background"),
            backgroundImage:
                generalSettings &&
                `url(${getSystemSettings(generalSettings, "type_logo")})`,
        },
    };

    return (
        <>
            <Helmet>
                <title>{_t(t("Check otp"))}</title>
            </Helmet>
            <main>
                <div className="fk-global-access">
                    <div className="d-none d-lg-block">
                        <div className="fk-global-img text-center">
                            <img
                                src="/assets/img/verifiy-img.png"
                                alt="khadyo"
                                className="img-fluid mx-auto fk-global-img__is"
                            />
                            <img
                                src="/assets/img/obj-1.png"
                                alt="khadyo"
                                className="img-fluid fk-global-img__obj fk-global-img__obj-1"
                            />
                            <img
                                src="/assets/img/obj-8.png"
                                alt="khadyo"
                                className="img-fluid fk-global-img__obj fk-global-img__obj-2"
                            />
                            <img
                                src="/assets/img/obj-7.png"
                                alt="khadyo"
                                className="img-fluid fk-global-img__obj fk-global-img__obj-6"
                            />
                            <img
                                src="/assets/img/obj-9.png"
                                alt="khadyo"
                                className="img-fluid fk-global-img__obj fk-global-img__obj-8"
                            />
                        </div>
                    </div>
                    <div className="container my-md-auto">

                        <div className="row">
                            <div className="col-md-8 col-lg-6 col-xl-4 t-mt-50">
                                <div className="fk-global-form">
                                    <div key="loading">
                                        <h2>Hey!!Something Went Wrong</h2>
                                        {/* <button onClick={refreshPage} className="btn btn-primary text-capitalize">refresh the page</button> */}
                                        <p>
                                            Try to refresh the page
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Preloaderone;
