import React from "react";

const SaasApiFailure = () => {
  return (
    <>
      <div className="fk-global-access">
        <div class="container my-md-auto">
          <div class="row justify-content-center align-items-center">
            <div class="col-12 col-md-8 col-lg-6">
              <div class="fk-global-form">
                <div className="text-center">
                  <p class="lead font-weight-normal text-danger">
                    Oops, something went wrong.
                  </p>
                  <p class="lead font-weight-normal text-danger">
                    Try again later.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SaasApiFailure;
