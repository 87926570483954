import React, { useEffect, useState } from "react";

//jQuery initialization
import $ from "jquery";
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  managePageHrefLink,
  managePageHrefLinkMobile,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

const Templatesidebar = () => {
  const { t } = useTranslation();
  const [templateValue, setTemplateValue] = useState(null);

  useEffect(() => {
    getActivatedTemplate();
    handleJquery();
  }, []);

  // getTemplate value
  const getActivatedTemplate = (e) => {
    const url = BASE_URL + "/settings/get-template-value";
    return axios.get(url).then((res) => {
      setTemplateValue(res.data);
      console.log("template sidebar", res);
    });
  };

  //jQuery goes here
  const handleJquery = () => {
    var posHasSub = $(".fk-pos-nav__list-has-sub");
    var posSub = $(".fk-pos-nav__sub");
    $(".fk-pos-nav__list-has-sub > a").on("click", function (e) {
      e.preventDefault();
    });
    posHasSub.on("click", function () {
      $(this).find(posSub).slideDown();
      $(this).siblings().find(posSub).slideUp();
      $(this).addClass("active").siblings().removeClass("active");
    });
  };

  return (
    <>
      {/* Navigation for Small Screen  */}
      <div className="d-lg-none">
        <div className="row">
          <div className="col-12">
            <div className="fk-sm-nav" data-simplebar>
              {templateValue == 1 ? (
                <ul className="t-list fk-sm-nav__bar flex-row">
                  {/* Template two settings */}
                  {/* {managePageHrefLinkMobile(
                    "/dashboard/template/hero-section",
                    _t(t("Hero"))
                  )} */}
                  {managePageHrefLinkMobile(
                    "/dashboard/template/hero-one-slider",
                    _t(t("Hero slider"))
                  )}

                  {managePageHrefLinkMobile(
                    "/dashboard/template/promotions",
                    _t(t("Promotions"))
                  )}
                </ul>
              ) : (
                <ul className="t-list fk-sm-nav__bar flex-row">
                  {/* Template two settings */}
                  {managePageHrefLinkMobile(
                    "/dashboard/template/edit-hero",
                    _t(t("Hero section"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/template/edit-about",
                    _t(t("About section"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/template/edit-feature",
                    _t(t("Feature section"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/template/edit-gellary",
                    _t(t("Gallery section"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/template/edit-delivery",
                    _t(t("Delivery section"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/template/change-template",
                    _t(t("Choose template"))
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Navigation for Small Screen  End*/}

      {/* Navigation for large Screen  */}
      <div className="d-none d-lg-block">
        <div className="fk-scroll--pos-menu" data-simplebar>
          <ul className="t-list fk-pos-nav">
            {/* Foods */}
            <li
              className={`fk-pos-nav__list fk-pos-nav__list-has-sub ${
                window.location.pathname.includes("/template/") ? "active" : ""
              }`}
            >
              <a
                className="w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase rounded-0 text-left"
                rel="noopener noreferrer"
                href="#"
              >
                {templateValue == 1
                  ? _t(t("Template one"))
                  : _t(t("Template two"))}
              </a>
              {templateValue == 1 ? (
                <ul className="t-list fk-pos-nav__sub t-bg-white list-group">
                  {/* {managePageHrefLink(
                    "/dashboard/template/hero-section",
                    _t(t("Hero"))
                  )} */}
                  {managePageHrefLink(
                    "/dashboard/template/promotions",
                    _t(t("Promotions"))
                  )}
                  {managePageHrefLink(
                    "/dashboard/template/hero-one-slider",
                    _t(t("Hero slider"))
                  )}
                  {managePageHrefLink(
                    "/dashboard/template/change-template",
                    _t(t("Choose template"))
                  )}
                </ul>
              ) : (
                <ul className="t-list fk-pos-nav__sub t-bg-white list-group">
                  {managePageHrefLink(
                    "/dashboard/template/edit-hero",
                    _t(t("Hero section"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/template/edit-about",
                    _t(t("About section"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/template/edit-feature",
                    _t(t("Feature section"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/template/edit-gellary",
                    _t(t("Gallery section"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/template/edit-delivery",
                    _t(t("Delivery section"))
                  )}

                  {managePageHrefLink(
                    "/dashboard/template/change-template",
                    _t(t("Choose template"))
                  )}
                </ul>
              )}
            </li>
          </ul>
        </div>
      </div>
      {/* Navigation for large Screen End */}
    </>
  );
};

export default Templatesidebar;
