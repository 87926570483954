import React, { useEffect, useState, useContext, useRef } from "react";
import { BASE_URL } from "../../../../../BaseUrl";
import { _t, getCookie } from "../../../../../functions/Functions";
import axios from "axios";
import { useTranslation } from "react-i18next";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    boxShadow: 24,
};

const SectionTables = ({ tablesGroupId }) => {
    // translation
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [allTables, setallTables] = useState([]);

    // selected table info
    const getSectionsTables = () => {
        setLoading(true);

        const tableUrl = BASE_URL + `/settings/get-sections-table-groups/[${tablesGroupId}]`;
        return axios
            .get(tableUrl, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                setallTables(res.data);
                // console.log(res);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };







    // useEffect
    useEffect(() => {
        getSectionsTables();
    }, [tablesGroupId]);

    // stopwatch useEffect

    return (
        <>

            <div className="rsv_right_table_plan_wrapper overflow-y-scroll w-100 height-100vh">

                {/* {allTables && allTables.map((tables)=> {

                })} */}
                {allTables && allTables.map((table, index) => {
                    return (
                        <>
                            <div
                                className={`single-table-layout mt-4 
                ${table.capacity > 10
                                        ? "large-table"
                                        : table.capacity > 6
                                            ? "traingle-table mx-4"
                                            : "circle-table"
                                    }
                ${table.status === "booked" ? "booked" : ""}
                m-1`}
                                key={index}
                            >
                                <span className="title">{table.name}</span>
                                <span className="d-inline-block text-white">
                                    {table.capacity}
                                </span>
                            </div>
                        </>
                    );
                })}

                {allTables === null || allTables.length === 0 ? [<h4>no tables assigned yet !!</h4>] : ""}
            </div>
        </>
    );
};

export default SectionTables;