import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Link, useParams } from "react-router-dom";
import ReservationMenu from "../layout/ReservationMenu";
import { Snackbar } from "@mui/material";
import { width } from "@mui/system";
import { useTranslation } from "react-i18next";
import NewSectionModal from "../modals/NewSectionModal";
import { BASE_URL } from "../../../../../BaseUrl";
import { _t, getCookie } from "../../../../../functions/Functions";
import axios from "axios";
import { toast } from "react-toastify";

const AssignTableCustomer = () => {
  let { id } = useParams();
  const { t } = useTranslation();
  const [showAssign, setshowAssign] = useState();
  const [showNotify, setshowNotify] = useState(false);
  const [showCheck, setshowCheck] = useState(false);
  const [allTables, setallTables] = useState(null);
  const [assignTable, setassignTable] = useState([]);
  const [assignTableToCustomer, setassignTableToCustomer] = useState([]);
  const [assignedClientInfo, setAssignedClientInfo] = useState();
  const [loading, setLoading] = useState(false);

  // filter table unassigned list
  const unaasignedTables =
    allTables &&
    allTables.filter((table) => table.assigned_to_section === "no");

  // console.log("unaasigned tables ", unaasignedTables);
  // handle modal show options
  const handleShowOptions = (e) => {
    if (e === "assign") {
      setshowAssign(true);
      setshowNotify(false);
      setshowCheck(false);
    } else if (e === "notify") {
      setshowAssign(false);
      setshowNotify(true);
      setshowCheck(false);
    } else if (e === "check") {
      setshowAssign(false);
      setshowNotify(false);
      setshowCheck(true);
    }
  };

  // handle add table
  const handlAddTable = (table) => {
    const isDuplicate = assignTableToCustomer.includes(table);

    if (!isDuplicate) {
      setassignTableToCustomer([...assignTableToCustomer, table]); //this is real item
    } else {
      toast.warning(`${_t(t("Table already added!!"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  // handle table booked alert open
  const handleBookedTableOpen = () => {
    toast.info(`${_t(t("This table is already booked!!"))}`, {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    });
  };

  // handle remove table
  const handleTableRemove = (table) => {
    let oldTable = assignTableToCustomer.filter(
      (mainTable) => mainTable.id != table.id
    );

    setassignTableToCustomer(oldTable);
    // console.log("remove table ", oldTable);
  };

  // get all tables
  const getAllTables = () => {
    setLoading(true);
    const tableUrl = BASE_URL + `/settings/all-table`;
    return axios
      .get(tableUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setallTables(res.data[1]);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get all assign tables
  const getAssignedTables = () => {
    setLoading(true);
    const tableUrl =
      BASE_URL + `/settings/show-assign-tables-to-customer/${id}`;
    return axios
      .get(tableUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setassignTableToCustomer(res.data);
        console.log("get  all assign tables ", res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get single reservation info
  const getSingleRsvInfo = () => {
    // get-single-rsv-info
    const singleRsvUrl = BASE_URL + `/settings/get-single-rsv-info/${id}`;
    return axios
      .get(singleRsvUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setAssignedClientInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect
  useEffect(() => {
    getAllTables();
    getAssignedTables();
    getSingleRsvInfo();
  }, []);

  // send data to backend
  const submitAssignTable = (e) => {
    e.preventDefault();

    const addUrl = BASE_URL + `/settings/assign-tables-to-customer/${id}`;

    let tableIds = assignTableToCustomer.map((item) => {
      return item.id;
    });

    const formData = {
      table_group_id: tableIds,
    };

    return axios
      .post(addUrl, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        // console.log(res);
        if (res.data.status === 200) {
          setLoading(false);
          setassignTable([]);
          toast.success(`${_t(t("Table assigned successfully"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        } else {
          toast.error(`${_t(t("Something went wrong!!"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
      })
      .catch((err) => {
        toast.error(`${_t(t("Something went wrong!!"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  return (
    <>
      {/* wraper */}
      <form onSubmit={submitAssignTable}>
        <div className="reservation_wrapper pt-3">
          <div className="rsv_right_content w-100">
            {/* author info bar  */}
            <div className="top-header d-flex flex-wrapp align-items-center justify-content-between">
              <div>
                <h4 className="text-white text-capitalize">
                  {assignedClientInfo && assignedClientInfo.name}
                </h4>
              </div>
              <Link
                to="/dashboard/reservation/waitlist-panel"
                className="btn btn-primary text-capitalize"
              >
                back to the waitlist
              </Link>
            </div>
            {/* top header-bar */}
            <div className="top-header d-flex flex-wrapp align-items-center justify-content-between">
              <div className="d-flex flex-wrap">
                {assignTableToCustomer.length > 0 ? (
                  [
                    assignTableToCustomer.map((table) => {
                      return (
                        <>
                          <div className="btn border border-2 rounded py-1 px-3 d-inline-block m-2 d-flex align-items-center text-white">
                            {table.name}
                            <span
                              className="d-inline-block ml-4 text-danger"
                              onClick={() => handleTableRemove(table)}
                            >
                              <i className="fas fa-times"></i>
                            </span>
                          </div>
                        </>
                      );
                    }),
                  ]
                ) : (
                  <div className="btn border border-2 rounded py-1 px-3 d-inline-block m-2 d-flex align-items-center text-white">
                    <span>Select table from below</span>
                  </div>
                )}
                <button
                  className="btn btn-success text-capitalize m-2 "
                  type="submit"
                >
                  assign table
                </button>
              </div>

              {/* back to list */}
              {/* <Link
                to="/dashboard/reservation/waitlist-panel"
                className="btn btn-primary text-capitalize"
              >
                back to the waitlist
              </Link> */}
            </div>
            {/* bottom content */}
            <div className="rsv_btm_content">
              {/* inner right side */}
              <div className="rsv_right_table_plan_wrapper overflow-y-scroll w-100 height-100vh">
                {allTables &&
                  allTables.map((table, index) => {
                    return (
                      <>
                        <div
                          onClick={(e) => {
                            // if customer table matched with assign table allow to edit booked-table else not

                            // if table is booked and length > 0 then handlAddTable(table)
                            // else if table is booked handleBookedTableOpen() else handlAddTable(table)
                            const tableMatched = assignTableToCustomer.filter(
                              (item) => item.id === table.id
                            );
                            console.log(
                              table.status === "booked" &&
                                tableMatched.length >= 0
                            );

                            {
                              table.status === "booked"
                                ? handleBookedTableOpen()
                                : handlAddTable(table);
                            }
                          }}
                          className={`single-table-layout mt-4 
                       ${
                         table.capacity > 10
                           ? "large-table"
                           : table.capacity > 6
                           ? "traingle-table mx-4"
                           : "circle-table"
                       }
                       ${table.status === "booked" ? "booked" : ""}
                       m-1`}
                          key={index}
                        >
                          <span className="title">{table.name}</span>
                          <span className="d-inline-block text-white">
                            {table.capacity}
                          </span>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AssignTableCustomer;
