import React, { useContext } from 'react';
import { BASE_URL } from '../../BaseUrl';
//functions
import {
    _t,
    getSystemSettings,
    pageLoading
} from "../../../src/functions/Functions";

//   context value
import { SettingsContext, loading, setLoading } from "../../contexts/Settings";

const DeliverySection = () => {
    //getting context values here
    let { templateOneSettings, loading } =
        useContext(SettingsContext);
    return (
        <>
            <section className="delivery-area padding-top-115 padding-bottom-90">
                <div className="del-shapes">
                    <span className="ds-1 item-bounce"><img src="/website/images/shapes/35.png" alt="" /></span>
                    <span className="ds-2"><img src="/website/images/shapes/34.png" alt="" /></span>
                    <span className="ds-3 item-bounce"><img src="/website/images/shapes/17.png" alt="" /></span>
                    <span className="ds-4 item-animateOne"><img src="/website/images/shapes/6.png" alt="" /></span>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 align-self-lg-center col-md-12 margin-bottom-20 wow fadeInLeft">
                            <div className="delivery-left">
                                {/* <img src="/website/images/bg/delivery-img.png" alt="" /> */}
                                <img src={`${BASE_URL}/uploads/${getSystemSettings(templateOneSettings, "deliveryImg")}`} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 wow fadeInRight">
                            <div className="delivery-right">
                                <div className="common-title-area padding-bottom-40">
                                    {/* <h3>delivery</h3> */}
                                    <h3>{getSystemSettings(templateOneSettings, "deliveryFancyTitle")}</h3>
                                    {/* <h2>A Moments of
                                        Delivered <span>
                                            On Right
                                            Time & Place
                                        </span> </h2> */}
                                    <h2>
                                        {getSystemSettings(templateOneSettings, "deliveryTitle")}
                                    </h2>
                                    {/* <p>Food Khan is a restaurant, bar and coffee roastery located on a busy corner site in
                                        Farringdon's Exmouth Market. With glazed frontage on two sides of the building,
                                        overlooking
                                        the market and a bustling London inteon.</p> */}
                                    <p>
                                        {getSystemSettings(templateOneSettings, "deliveryContent")}
                                    </p>
                                    <div className="order-box d-flex align-items-end">
                                        <span className="order-img"><img src="/website/images/icons/1.png" alt="" /></span>
                                        <div className="order-content">
                                            <p>delivery order num.</p> <span>
                                                {getSystemSettings(templateOneSettings, "deliveryNumber")}
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DeliverySection;
