import React, { useEffect, useContext, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";
import { _t, getCookie } from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ManageSidebar from "../ManageSidebar";
import { SettingsContext } from "../../../../../contexts/Settings";

const ShowSingleRecipe = () => {
  const { recipeId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, setLoading, dataPaginating } = useContext(SettingsContext);
  const [Recipes, setRecipes] = useState({});

  const getRecipes = () => {
    setLoading(true);
    const url = `${BASE_URL}/settings/get-all-single-recipe/${recipeId}`;
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        const data = res.data;
        setRecipes(data);

        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getRecipes();
  }, [recipeId]);

  return (
    <>
      <Helmet>
        <title>{_t(t("Recipe"))}</title>
      </Helmet>

      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>

            <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    <div className={`${dataPaginating && "loading"}`}></div>

                    <div key="smtp-form">
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15 mb-3">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {/* {_t(t("Recipe"))} */}
                                {Recipes && Recipes.name}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-7">
                          <div className="row gx-3 align-items-center justify-content-end">
                            <Link
                              to={"/dashboard/manage/food/all-recipes"}
                              className="btn btn-danger text-capitalize w-auto"
                            >
                              back to recipe list
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <h5 className="m-0 mb-4 text-white px-4 py-2  BlackButton">
                            {_t(t("Instructions"))} {":"}
                          </h5>
                          <div className="border p-2">
                            <p>
                              {Recipes && Recipes.instructions
                                ? Recipes.instructions
                                : "No instructions added yet!"}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <h5 className="m-0 mb-4 text-white px-4 py-2  BlackButton">
                            Ingredients:
                          </h5>
                          <ul className="list-group">
                            {Recipes && Recipes.recipe_ingredients?.length > 0 ? (
                              Recipes.recipe_ingredients?.map((ingredient) => (
                                <li
                                  key={ingredient.id}
                                  className="list-group-item d-flex justify-content-between align-items-center"
                                >
                                  {ingredient.ingredient_item.name}
                                  <span className="badge bg-primary">
                                    {ingredient.amount_of_ingredient}{" "}
                                    {ingredient.ingredient_item.unit}
                                  </span>
                                </li>
                              ))
                            ) : (
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                No ingredients found!
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ShowSingleRecipe;
