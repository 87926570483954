import React from "react";
import { Box, Skeleton } from "@mui/material";

const sidebarSkeleton = () => {
  return (
    <Box
      sx={{
        width: "300",
      }}
    >
      <Skeleton
        sx={{
          height: 140,
        }}
        animation="wave"
      />
      <Skeleton
        sx={{
          height: 140,
        }}
        animation="wave"
      />
      <Skeleton
        sx={{
          height: 140,
        }}
        animation="wave"
      />
      <Skeleton
        sx={{
          height: 140,
        }}
        animation="wave"
      />
    </Box>
  );
};

export { sidebarSkeleton };
