import React, { useEffect, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  getSystemSettings,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import Templatesidebar from "../../template/Templatesidebar";

import { confirmAlert } from "react-confirm-alert";

const HeroSliders = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let {
    loading,
    setLoading,
    generalSettings,
    setGeneralSettings,
    getSettings,
    dataPaginating,
  } = useContext(SettingsContext);

  //get sliders values
  const [allSliders, setallSliders] = useState(null);

  // add new slider details
  const [addNewSlider, setaddNewSlider] = useState({
    title: "",
    subtitle_1: "",
    subtitle_2: "",
    sliderId: null,
    hero_img: null,
  });

  //set image hook
  const handleItemImage = (e) => {
    setaddNewSlider({
      ...addNewSlider,
      [e.target.name]: e.target.files[0],
    });
  };

  //on change input field
  const handleChange = (e) => {
    setaddNewSlider({ ...addNewSlider, [e.target.name]: e.target.value });
  };

  //   get all sliders item
  const getSliders = () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-hero-sliders";
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setallSliders(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  //send req to server add new
  const handleAddNewSlider = (e) => {
    e.preventDefault();
    setLoading(true);
    let formData = new FormData();

    formData.append("title", addNewSlider.title);
    formData.append("subtitle_1", addNewSlider.subtitle_1);
    formData.append("subtitle_2", addNewSlider.subtitle_2);
    formData.append("image", addNewSlider.hero_img);
    const url = BASE_URL + "/settings/add-new-hero-slider";
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setallSliders(res.data.data);
        setLoading(false);

        toast.success(`${_t(t("Settings updated successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch((error) => {
        setLoading(false);
        toast.error(`${_t(t("Please try again!!"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  //send req to server edit
  const handleEditSlider = (e) => {
    e.preventDefault();
    setLoading(true);
    let formData = new FormData();

    formData.append("title", addNewSlider.title);
    formData.append("subtitle_1", addNewSlider.subtitle_1);
    formData.append("subtitle_2", addNewSlider.subtitle_2);
    formData.append("slider_id", addNewSlider.sliderId);
    formData.append("image", addNewSlider.hero_img);
    const url = BASE_URL + "/settings/edit-new-hero-slider";
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setallSliders(res.data.data);
        setLoading(false);

        toast.success(`${_t(t("Settings updated successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch((error) => {
        setLoading(false);
        toast.error(`${_t(t("Please try again!!"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  //delete confirmation modal of slider
  const handleDeleteConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body pb-5 px-5">
            <h4>{_t(t("Are you sure?"))}</h4>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDeleteSlider(id);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //delete slider here
  const handleDeleteSlider = (id) => {
    setLoading(true);
    const sliderUrl = BASE_URL + `/settings/delete-hero-slider/${id}`;
    return axios
      .get(sliderUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setallSliders(res.data);
        toast.success(`${_t(t("Slider has been deleted successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  //useEffect == componentDidMount()
  useEffect(() => {
    getSliders();
  }, []);

  return (
    <>
      <Helmet>
        <title>{_t(t("Hero section"))}</title>
      </Helmet>

      {/* Add modal */}
      <div className="modal fade" id="imgId" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleAddNewSlider}
                className="row card p-2 mx-3 mb-5 sm-text"
              >
                <div className="form-group mt-4">
                  <div className="mb-2">
                    <label for="title" className="control-label">
                      title
                    </label>
                  </div>
                  <div className="mb-2">
                    <input
                      required
                      name="title"
                      type="text"
                      defaultValue=""
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      for="subtitle_1"
                      className="control-label text-capitalize"
                    >
                      subtitle 1
                    </label>
                  </div>
                  <div className="mb-2">
                    <input
                      required
                      name="subtitle_1"
                      defaultValue=""
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      for="subtitle_2"
                      className="control-label text-capitalize"
                    >
                      subtitle 2
                    </label>
                  </div>
                  <div className="mb-2">
                    <input
                      required
                      name="subtitle_2"
                      type="text"
                      defaultValue=""
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      for="image"
                      className="control-label text-capitalize"
                    >
                      Image
                    </label>
                  </div>
                  <div className="mb-2">
                    <input
                      required
                      type="file"
                      onChange={handleItemImage}
                      className="form-control sm-text"
                      name="hero_img"
                    />
                  </div>
                  <div className="form-group mt-5 pb-2">
                    <div className="col-lg-12">
                      <button className="btn btn-primary px-5" type="submit">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}

      {/* Edit modal */}
      <div className="modal fade" id="editModal" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleEditSlider}
                className="row card p-2 mx-3 mb-5 sm-text"
              >
                <div className="form-group mt-4">
                  <div className="mb-2">
                    <label for="title" className="control-label">
                      title
                    </label>
                  </div>
                  <div className="mb-2">
                    <input
                      required
                      name="title"
                      type="text"
                      defaultValue={
                        addNewSlider && addNewSlider != ""
                          ? addNewSlider.title
                          : ""
                      }
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      for="subtitle_1"
                      className="control-label text-capitalize"
                    >
                      subtitle 1
                    </label>
                  </div>
                  <div className="mb-2">
                    <input
                      required
                      name="subtitle_1"
                      defaultValue={
                        addNewSlider && addNewSlider != ""
                          ? addNewSlider.subtitle_1
                          : ""
                      }
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      for="subtitle_2"
                      className="control-label text-capitalize"
                    >
                      subtitle 2
                    </label>
                  </div>
                  <div className="mb-2">
                    <input
                      required
                      name="subtitle_2"
                      type="text"
                      subtitle_1
                      defaultValue={
                        addNewSlider && addNewSlider != ""
                          ? addNewSlider.subtitle_2
                          : ""
                      }
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      for="image"
                      className="control-label text-capitalize"
                    >
                      Image
                    </label>
                  </div>
                  <div className="mb-2">
                    <input
                      type="file"
                      onChange={handleItemImage}
                      className="form-control sm-text"
                      name="hero_img"
                    />
                  </div>
                  <div className="form-group mt-5 pb-2">
                    <div className="col-lg-12">
                      <button className="btn btn-primary px-5" type="submit">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Edit modal Ends*/}

      {/* delete modal */}
      <div className="modal fade" id="deleteConfirmModal" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleAddNewSlider}
                className="row card p-2 mx-3 mb-5 sm-text"
              >
                <div className="form-group mt-4">
                  <div className="mb-2">
                    <label for="title" className="control-label">
                      title
                    </label>
                  </div>
                  <div className="mb-2">
                    <input
                      required
                      name="title"
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      for="subtitle_1"
                      className="control-label text-capitalize"
                    >
                      subtitle 1
                    </label>
                  </div>
                  <div className="mb-2">
                    <input
                      required
                      name="subtitle_1"
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      for="subtitle_2"
                      className="control-label text-capitalize"
                    >
                      subtitle 2
                    </label>
                  </div>
                  <div className="mb-2">
                    <input
                      required
                      name="subtitle_2"
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      for="image"
                      className="control-label text-capitalize"
                    >
                      Image
                    </label>
                  </div>
                  <div className="mb-2">
                    <input
                      type="file"
                      onChange={handleItemImage}
                      className="form-control sm-text"
                      name="hero_img"
                    />
                  </div>
                  <div className="form-group mt-5 pb-2">
                    <div className="col-lg-12">
                      <button className="btn btn-primary px-5" type="submit">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* delete modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              <Templatesidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}
                    <div className="d-flex justify-content-end ">
                      <button
                        className="btn btn-primary my-4 text-capitalize"
                        data-toggle="modal"
                        data-target="#imgId"
                        onClick={() =>
                          setaddNewSlider({
                            title: "",
                            subtitle_1: "",
                            subtitle_2: "",
                            hero_img: null,
                            sliderId: null,
                          })
                        }
                      >
                        add new
                      </button>
                    </div>
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th
                            className="text-center text-capitalize"
                            scope="col"
                          >
                            #
                          </th>
                          <th
                            className="text-center text-capitalize"
                            scope="col"
                          >
                            Title
                          </th>
                          <th
                            className="text-center text-capitalize"
                            scope="col"
                          >
                            Subtitle 1
                          </th>
                          <th
                            className="text-center text-capitalize"
                            scope="col"
                          >
                            Subtitle 2
                          </th>
                          <th
                            className="text-center text-capitalize"
                            scope="col"
                          >
                            Hero img
                          </th>
                          <th
                            className="text-center text-capitalize"
                            scope="col"
                          >
                            Edit
                          </th>
                          <th
                            className="text-center text-capitalize"
                            scope="col"
                          >
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading == true
                          ? tableLoading()
                          : [
                              allSliders &&
                                allSliders.map((item, index) => {
                                  return (
                                    <>
                                      <tr>
                                        <th scope="row">{index + 1}</th>
                                        <td className="text-center">
                                          {item.title}
                                        </td>
                                        <td className="text-center">
                                          {item.subtitle_1}
                                        </td>
                                        <td className="text-center">
                                          {item.subtitle_2}
                                        </td>
                                        <td className="text-center">
                                          <img
                                            width={100}
                                            src={`${BASE_URL}/${item.hero_img}`}
                                            alt=""
                                          />
                                          {/* {item.hero_img} */}
                                        </td>
                                        <td className="text-center">
                                          <button
                                            data-toggle="modal"
                                            data-target="#editModal"
                                            className="btn btn-sm btn-secondary"
                                            onClick={() =>
                                              setaddNewSlider({
                                                title: item.title,
                                                subtitle_1: item.subtitle_1,
                                                subtitle_2: item.subtitle_2,
                                                hero_img: null,
                                                sliderId: item.id,
                                              })
                                            }
                                          >
                                            <i class="far fa-edit"></i>
                                          </button>
                                        </td>
                                        <td className="text-center">
                                          <button
                                            onClick={() => {
                                              handleDeleteConfirmation(item.id);
                                            }}
                                            className="btn btn-sm btn-primary"
                                          >
                                            <i class="fas fa-trash-alt"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                }),
                            ]}
                      </tbody>
                    </table>

                    {/* Loading effect */}
                    {/* {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Hero section"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center"></div>
                          </div>
                        </div> */}

                    {/* Form starts here */}
                    {/* <form
                          className="row card p-2 mx-3 sm-text my-2"
                          onSubmit={handleSubmit}
                        >
                          <div className="form-group mt-2">
                            <div className="mb-2">
                              <label htmlFor="vat" className="control-label">
                                {_t(t("Sub-heading-1"))}
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                type="text"
                                className="form-control sm-text"
                                name="subHeading1"
                                onChange={handleChange}
                                value={newSettings.subHeading1}
                                placeholder="Best In Town"
                                required
                              />
                            </div>
                          </div>

                          <div className="form-group mt-3">
                            <div className="mb-2">
                              <label htmlFor="vat" className="control-label">
                                {_t(t("Heading"))}
                              </label>
                            </div>
                            <div className="mb-2">
                              <textarea
                                rows="3"
                                type="text"
                                onChange={handleChange}
                                value={newSettings.heading}
                                className="form-control sm-text"
                                name="heading"
                                placeholder="Enjoy Our Chicken Burger Fast Food"
                                required
                              />
                            </div>
                          </div>

                          <div className="form-group mt-3">
                            <div className="mb-2">
                              <label htmlFor="vat" className="control-label">
                                {_t(t("Sub-heading-2"))}
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                type="text"
                                className="form-control sm-text"
                                name="subHeading2"
                                onChange={handleChange}
                                value={newSettings.subHeading2}
                                placeholder="Bacon-Potatos-Bbq Sauce"
                                required
                              />
                            </div>
                          </div>

                          <div className="form-group mt-3">
                            <div className="mb-2">
                              <label htmlFor="vat" className="control-label">
                                {_t(t("Image"))}
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                type="file"
                                onChange={handleItemImage}
                                className="form-control sm-text"
                                name="image"
                              />
                            </div>
                          </div>

                          <div className="form-group mt-4 pb-2">
                            <div className="col-lg-12">
                              <button
                                className="btn btn-primary px-5"
                                type="submit"
                              >
                                {_t(t("Save"))}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default HeroSliders;
